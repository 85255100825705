import { combineReducers } from 'redux';
import boardReducer from './board/board.reducer';
import userReducer from './user/user.reducer';
import dbReducer from './db/db.reducer';

const rootReducer = combineReducers({
  board: boardReducer,
  user: userReducer,
  db: dbReducer,
});

export default rootReducer;
