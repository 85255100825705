import _ from 'lodash';
import { Database } from './Database';

class Services {
  async init() {
    await Database.init('admin@scatterblue.com', 'Scatterblue#123');
  }

  async signIn(user, password) {
    localStorage.setItem('elmos-hw-guide-UserName', user);
    localStorage.setItem('elmos-hw-guide-Password', password);
    await Database.init(user, password);
  }

  async signOut(user, password) {
    localStorage.setItem('elmos-hw-guide-UserName', '');
    localStorage.setItem('elmos-hw-guide-Password', '');
  }

  /**
   * Get boards and configurations
   * Used to populate boards and configuration listing
   */
  /*
    Returns:
        [
            {
                id: "",
                name: "",
                info: "",
                configurations: [
                    {
                        id: "",
                        name: "",
                        info: ""
                    }
                ]
            }
        ]
    */
  async getBoards() {
    let resp = await Database.find('boards', { fields: '*.*' });
    console.log('BOARDS', resp);
    return resp;
  }

  /**
   * Get complete board details along with configurations
   * Used to populate board overview, board configuration
   * @param {*} id board id
   */
  /*
    Returns:
        {
            id: "",
            name: "",
            info: "",
            overview: "html", 
            image: "file",
            configurations: [
                {
                    id: "",
                    name: "",
                    info: "",
                    full_settings: [
                        {
                            id: "",
                            info: "",
                            patterns: [uuid,uuid,..]
                            selected: [index,..]
                        }
                    ]
                    part_settings: [
                        {
                            id: "",
                            info: "",
                            full_settings: {full_settings}
                            selected: [index,..]
                        }
                    ]
                }
            ]
        }
    */
  async getBoard(id) {
    let resp = await Database.findOne('boards', id, '*.*.*');
    console.log('BOARD: ', resp);
    return resp;
  }

  /**
     Add/update board. id required for update
        {
            id: "" 
            name: "",
            info: "",
            overview: "html",
            status: "dev|prod"
        }
     */
  async saveBoard(data, file) {
    let params = _.pick(data, ['name', 'info', 'overview', 'status']);
    let resp;
    let image = null;
    if (file) {
      image = await this.uploadFile(file);
      params.image = image.id;
    }

    if (data.id) {
      resp = await Database.update('boards', data.id, params);
      console.log('BOARD Updated:', resp);
    } else {
      resp = await Database.create('boards', params);
      console.log('BOARD Created:', resp);
      const config = {
        name: 'Master',
        info: 'Master board configuration',
        overview: 'Defines all configurable elements of the board',
        board: resp.id,
      };
      await this.saveConfiguration(config, true);
    }
    return resp;
  }

  imageUrl(id, w, h) {
    // http://13.233.47.126:3600/assets/<file-id>?fit=<fit>&width=<width>&height=<height>&quality=<quality>
    // http://13.233.47.126:3600/assets/7758363a-fc34-41d1-ac79-f256b553250c?fit=cover&width=200&height=200&quality=80
    if (w || h) {
      return `${Database.url}assets/${id}?width=${w}&height=${h}&quality=100`;
    } else {
      return `${Database.url}assets/${id}?quality=100`;
    }
  }

  // /**
  //  * Set board image
  //  * @param {*} id
  //  * @param {*} file
  //  * @returns
  //  */
  // async saveBoardImage(id, file) {
  //     let resp = await this.uploadFile(file);
  //     resp = await Database.update('boards', id, {image: resp.id})
  //     return resp;
  // }

  /**
   * Upload file and get resource id of the uploaded image
   * @param {*} file
   * @returns
   */
  async uploadFile(file) {
    var formData = new FormData(); // Currently empty
    formData.append('board', file, 'elmosboard.jpg');
    console.log('FILE ATTACHED....', file.name);
    let resp = await Database.uploadFile(formData);
    console.log('FILE UPLOADED:', resp);
    return resp;
    /*  File: 
            charset: null
            description: null
            duration: null
            embed: null
            filename_disk: "dddd8003-646c-47f2-b17c-b3379a0ca758.jpg"
            filename_download: "chris.jpg"
            filesize: 161670
            folder: null
            height: 960
            id: "dddd8003-646c-47f2-b17c-b3379a0ca758"
            location: null
            metadata: null
            modified_by: null
            modified_on: "2022-02-15T18:32:44.000Z"
            storage: "local"
            tags: null
            title: "Chris"
            type: "image/jpeg"
            uploaded_by: "7c3e5960-5558-480f-8d36-4daaefe2fc3f"
            uploaded_on: "2022-02-15T18:32:43.000Z"
            width: 1280        
        */
  }

  /**
     Add/update configuration. id required for update
        {
            id: "" 
            name: "",
            info: "",
            overview: "",
            board: "{board id}"
        }
     */

  async getConfig(id) {
    let resp = await Database.findOne('configurations', id, '*.*.*');
    console.log('BOARD: ', resp);
    return resp;
  }

  async saveConfiguration(data, isMaster) {
    let params = _.pick(data, ['name', 'info', 'overview', 'board']);
    let resp;
    params.is_master = isMaster ? true : false;
    if (data.id) {
      resp = await Database.update('configurations', data.id, params);
      console.log('CONFIG Updated:', resp);
    } else {
      resp = await Database.create('configurations', params);
      console.log('CONFIG Created:', resp);
    }
    return resp;
  }

  /**
     Add/update fullsetting. id required for update
        [{
            id: "" 
            info: "",
            patterns: "",
            selected: ""
            left: "",
            top:"",
            width: "",
            height: ""
            angle: "",
            configuration: "{configuration id}"
        }]
     */
  async saveFullSettings(confgurationId, settings) {
    let promises = [];
    let createItems = [];
    let deleteItems = [];

    console.log("SAVIG SETTINGS...:", JSON.stringify(settings));

    let oldSettings = await Database.find('full_settings', {
      fields: 'id',
      filter: {
        configuration: {
          _eq: confgurationId,
        },
      },
      limit: 5000,
    });

    console.log('OLD SETTINGS: ', oldSettings);

    if (oldSettings) {
      oldSettings.forEach((s) => {
        let found = settings.find((e) => {
          return e.id && e.id === s.id;
        });
        if (!found) {
          deleteItems.push(s.id);
        }
      });
    }

    settings.forEach((data) => {
      let params = _.pick(data, [
        'info',
        'patterns',
        'label',
        'selected',
        'left',
        'top',
        'width',
        'height',
        'angle',
        'configuration',
      ]);
      if (data.id) {
        promises.push(Database.update('full_settings', data.id, params));
      } else {
        createItems.push(params);
      }
    });
    if (createItems.length)
      promises.push(Database.createMany('full_settings', createItems));
    if (deleteItems.length)
      promises.push(Database.deleteMany('full_settings', deleteItems));

    let resp = await Promise.all(promises);
    console.log('SAVED FULL SETTINGS:', resp);
    return resp;
  }

  /**
     Add/update incremental setting. id required for update
        {
            id: "" 
            info: "",
            full_setting: "",
            selected: "",
            configuraton: "{configuration id}"
        }
    */
  async savePartSettings(confgurationId, settings) {
    let promises = [];
    let createItems = [];
    let deleteItems = [];

    let oldSettings = await Database.find('part_settings', {
      fields: 'id',
      filter: {
        configuration: {
          _eq: confgurationId,
        },
      },
      limit: 5000,
    });

    console.log('OLD PART SETTINGS: ', oldSettings);

    if (oldSettings) {
      oldSettings.forEach((s) => {
        let found = settings.find((e) => {
          return e.id && e.id === s.id;
        });
        if (!found) {
          deleteItems.push(s.id);
        }
      });
    }

    settings.forEach((data) => {
      let params = _.pick(data, [
        'info',
        'label',
        'full_setting',
        'selected',
        'configuration',
      ]);
      if (data.id) {
        promises.push(Database.update('part_settings', data.id, params));
      } else {
        createItems.push(params);
      }
    });
    if (createItems.length)
      promises.push(Database.createMany('part_settings', createItems));
    if (deleteItems.length)
      promises.push(Database.deleteMany('part_settings', deleteItems));

    let resp = await Promise.all(promises);
    console.log('SAVED PART SETTINGS', resp);
    return resp;
  }

  /**
   * Get patterns
   * Used to show patterns list
   */
  /*
    Response:
        [
            {
                id: "integer",
                name: "string",
                info: "string",
                image: "file",
                hidden: "boolean"
            }
        ]
    */
  async getPatterns(fields) {
    let resp = await Database.find('patterns', { fields: fields || '*.*' });
    console.log('PATTERNS', resp);
    return resp;
  }

  /**
   * Add new pattern
   */
  /*
        Data:
        {
            name: "string",
            info: "string",
            image: "file",
            hidden: "bool"
        }
     */
  async savePattern(data, file) {
    let params = _.pick(data, ['name', 'info', 'hidden']);
    let resp;
    let image = null;
    if (file) {
      image = await this.uploadFile(file);
      params.image = image.id;
    }

    if (data.id) {
      resp = await Database.update('patterns', data.id, params);
      console.log('PATTERN Updated:', resp);
    } else {
      resp = await Database.create('patterns', params);
      console.log('PATTERN Created:', resp);
    }
    return resp;
  }

  async savePatternImage(id, file) {
    let resp = await this.uploadFile(file);
    resp = await Database.update('patterns', id, { image: resp.id });
    return resp;
  }
}
export const Service = new Services();
