import React, { Component } from 'react';
import ResizableRect from 'react-resizable-rotatable-draggable';
import { Service } from './Service';

class ImageAnnotate extends Component {
  constructor() {
    super();
    this.state = {
      width: 50,
      height: 50,
      top: 0,
      left: 0,
      rotateAngle: 0,
      boards: [],
      board: {},
    };
    Service.init();
  }

  componentDidMount = () => {
    console.log('COMP Mounted..');
  };

  handleResize = (style, isShiftKey, type) => {
    // type is a string and it shows which resize-handler you clicked
    // e.g. if you clicked top-right handler, then type is 'tr'
    let { top, left, width, height } = style;
    top = Math.round(top);
    left = Math.round(left);
    width = Math.round(width);
    height = Math.round(height);
    this.setState({
      top,
      left,
      width,
      height,
    });
  };

  handleRotate = (rotateAngle) => {
    this.setState({
      rotateAngle,
    });
  };

  handleDrag = (deltaX, deltaY) => {
    this.setState({
      left: this.state.left + deltaX,
      top: this.state.top + deltaY,
    });
  };

  getBoards = () => {
    (async () => {
      let resp = await Service.getBoards();
      this.setState({ boards: resp });
    })();
  };

  async getBoard() {
    (async () => {
      if (this.state.boards[0]) {
        let resp = await Service.getBoard(this.state.boards[0].id);
        this.setState({ board: resp });
      }
    })();
  }

  addBoard = () => {
    (async () => {
      await Service.saveBoard({
        name: 'Test board',
        info: 'Board for testing',
        overview: '<h1> This is test board </h1>',
      });
    })();
  };

  setBoardImage = (file) => {
    (async () => {
      await Service.uploadFile(file);
    })();
  };

  addConfig = () => {
    (async () => {
      if (this.state.boards[0]) {
        const config = {
          name: 'Test config',
          info: 'Test configuration 1',
          overview: 'About some application',
          board: this.state.boards[0].id,
        };
        await Service.saveConfiguration(config);
      }
    })();
  };

  render() {
    const { width, top, left, height, rotateAngle } = this.state;
    let boards = this.state.boards || [];

    return (
      <div className="App">
        <div style={{ width: 200, height: 200, backgroundColor: 'lightgrey' }}>
          <ResizableRect
            left={left}
            top={top}
            width={width}
            height={height}
            rotateAngle={rotateAngle}
            // aspectRatio={false}
            // minWidth={10}
            // minHeight={10}
            //zoomable='n, w, s, e, nw, ne, se, sw'
            zoomable="se"
            rotatable={false}
            // onRotateStart={this.handleRotateStart}
            onRotate={this.handleRotate}
            // onRotateEnd={this.handleRotateEnd}
            // onResizeStart={this.handleResizeStart}
            onResize={this.handleResize}
            // onResizeEnd={this.handleUp}
            // onDragStart={this.handleDragStart}
            onDrag={this.handleDrag}
            // onDragEnd={this.handleDragEnd}
          />
        </div>

        <h2>Manage Boards</h2>
        <button onClick={() => this.addBoard()}> Add Board </button>
        <input
          ref="boardImage"
          id="fileInput"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => this.setBoardImage(e.target.files[0])}
        />
        <input
          type="button"
          value="Set Image"
          onClick={() => {
            this.refs['boardImage'].click();
          }}
        />

        {/*<form>
        <input
          type="file"
          onChange={(e) => this.uploadFile(e.target.files[0])}
        />
      </form>*/}

        <h2>Boards List </h2>
        <button onClick={() => this.getBoards()}>Get Boards</button>

        <ul>
          {' '}
          {boards.map((board, index) => (
            <li key={index}>
              {board.name} ({board.info})
            </li>
          ))}
        </ul>

        <h2> Board Details </h2>
        <button onClick={() => this.getBoard()}>Get Board</button>
      </div>
    );
  }
}

export default ImageAnnotate;
