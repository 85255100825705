import { boardActionTypes } from './board.types';

const INITIAL_STATE = {
  boardList: [],
  currentBoard: null,
  patternList: [],
  settingsList: [],
};

const boardReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case boardActionTypes.SET_CURRENT_BOARD:
      return {
        ...currentState,
        currentBoard: action.payload,
      };

    case boardActionTypes.SET_BOARD_LIST:
      return {
        ...currentState,
        boardList: action.payload,
      };

    case boardActionTypes.SET_CURERNT_CONFIG:
      return {
        ...currentState,
        currentConfig: action.payload,
      };

    case boardActionTypes.SET_PATTERN_LIST:
      return {
        ...currentState,
        patternList: action.payload,
      };

    case boardActionTypes.SET_SETTINGS_LIST:
      return {
        ...currentState,
        settingsList: action.payload,
      };

    default:
      return currentState;
  }
};

export default boardReducer;
