import { userActionTypes } from './user.types';

const INIT_STATE = {
  currentUser: null,
  dbToken: null,
};

const userReducer = (currentState = INIT_STATE, action) => {
  switch (action.type) {
    case userActionTypes.SET_CURRENT_USER:
      return {
        ...currentState,
        currentUser: action.payload,
      };
    case userActionTypes.LOG_OUT:
      return {
        ...currentState,
        currentUser: null,
      };

    case userActionTypes.SET_DB_TOKEN:
      return {
        ...currentState,
        dbToken: action.payload,
      };

    default:
      return currentState;
  }
};
export default userReducer;
