import { Directus } from '@directus/sdk';
import _ from 'lodash';

export class Database {
    static directus;
    static token;
    static collections;
    static url = 'http://13.233.47.126:3600/';

    static async init(user, password) {
        console.log("DB Init..");
        if(this.directus && this.token)
            return;
        this.directus = new Directus(this.url);
        this.token = await this.directus.auth.login({
            email: user,
            password: password,
            });
        console.log("T: ", new Date().getTime(), "/", this.token);
        this.collections = {}
    }
    
    static async client() {
        return this.directus;
    }

    static async uploadFile(form) {
        console.log("FILES:", this.directus.files);
        return await this.directus.files.createOne(form);
    }

    static getCollection(coll) {
        if(!this.collections[coll])
            this.collections[coll] = this.directus.items(coll)

        if(!this.collections[coll]) {
            throw new Error(`Invalid collection ${coll}`);
        }
        return this.collections[coll];
    }

    static async create(collection, data) {
        const resp = await this.getCollection(collection).createOne(data);
        return resp;
    }

    static async createMany(collection, data) {
        const resp = await this.getCollection(collection).createMany(data);
        return resp;
    }

    static async deleteMany(collection, ids) {
        const resp = await this.getCollection(collection).deleteMany(ids);
        return resp;
    }

    static async update(collection, id, data) {
        const resp = await this.getCollection(collection).updateOne(id, data);
        return resp;
    }

    static async updateMany(collection, ids, data) {
        const resp = await this.getCollection(collection).updateMany(ids, data);
        return resp;
    }

    static async findOne(collection, id, fields) {
        const resp = await this.getCollection(collection).readMany({
            fields: fields,
            filter: {
                id: {
                    _eq: id,
                },
            },
            limit: 1,
        });

        // Update existing vendor
        if(resp.data && resp.data.length)
            return resp.data[0];
        return null;
    }

    static async find(collection, params) {
        if(params.filter && !_.isObject(params.filter))
            params.filter = JSON.parse(params.filter)
        if(params.limit)
            params.limit = Number(params.limit)
        if(params.offset)
            params.offset = Number(params.offset)
        if(params.page)
            params.page = Number(params.page)

        const resp = await this.getCollection(collection).readMany(params);
        return resp.data;
    }

    static async file(id) {
        this.directus.files()
    }
}
