import { dbActionTypes } from './dbActionTypes.js';
const INITIAL_STATE = {
  initialized: false,
};

const dbReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case dbActionTypes.SET_INITIALIZED:
      return {
        ...currentState,
        initialized: action.payload,
      };

    default:
      return currentState;
  }
};

export default dbReducer;
